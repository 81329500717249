

.header {
    width: 642px;
    margin-top: 0;
    display: flex;
    justify-content: center;
}

.header h1 {
    margin-top: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.11);
    border-radius: 10px;
    width: 100%;
}

.footer {
    text-align: center;
    font-size: 0.8em;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .header {
        width: 380px;
    }
}