.radio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    color: rgba(173, 125, 255, 0.67);
}

.info {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.info span:first-of-type {
    color: rgba(253, 201, 220, 0.448);
    font-weight: bold;
}

.add {
    cursor: pointer;
    color: rgba(255, 125, 75, 0.87);
}

.add:hover {
    color: rgba(255, 50, 75, 0.87);
}