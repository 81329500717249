.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 600px;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 20px;
}

@media screen and (max-width: 768px) {
    .dashboard {
        width: 338px;
    }
}