.patroller {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    color: rgba(255, 157, 71, 0.87);
}

.header > span span:last-of-type {
    margin-left: 5px;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.87);
}

.profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 95%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    line-height: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    min-height: 100px;
    break-after: always;
}

.info span {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.87);
}

.info span:first-of-type {
    color: rgba(77, 183, 209, 0.67);
    font-weight: 800;
}

.info input[type="text"], input[type="number"], input[type="tel"], input[type="email"] {
    font-size: 22px !important;
    min-width: 250px !important;
    width: 260px !important;
}

.certifications {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
    width: 100%;
}

.badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 70px;
    height: 70px;
    font-size: 20px;
    line-height: 22px;
}

.badge img {
    width: 100%;
    height: 100%;
}

.edit {
    cursor: pointer;
    color: rgba(255, 125, 75, 0.87);
}

.edit:hover {
    color: rgba(255, 50, 75, 0.87);
}

.save {
    cursor: pointer;
    color: rgba(61, 90, 12, 0.87);
}

.save:hover {
    color: rgba(124, 181, 24, 0.87);
}

.avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    overflow: clip;
    position: relative;
    border-radius: 10px;
    background-color: rgba(77, 183, 209, 0.67);
    font-size: 80px;
    line-height: 80px;
}

.avatar span {
    font-size: 12px;
    line-height: 14px;
    position: absolute;
    height: 14px;
    width: 40px;
    padding: 2px;
    text-align: center;
    vertical-align: top;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(77, 183, 209, 0.87);
}

.avatar div {
    width: 96%;
    height: 96%;
    object-fit: cover;
    position: absolute;
    border-radius: 10px;
    background-image: url('https://patrol360.dev/assets/profile_s.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.avatar img {
    width: 96%;
    height: 96%;
    object-fit: cover;
    position: absolute;
    border-radius: 10px;
}

.hidden {
    display: none;
}

@media screen and (max-width: 768px) {
    .badge {
        width: 50px;
        height: 50px;
    }
}