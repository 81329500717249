.overlay {
    position: fixed; /* Fixed position */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.90); /* Semi-transparent black background */
    z-index: 1000; /* Ensure it appears on top */
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: fit-content;
    min-width: 350px;
    max-width: 450px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 0 15px 5px 15px;
    box-sizing: border-box;
}

.close {
    font-weight: 900;
    font-size: 18px;
    color: rgba(246, 100, 100, 0.8);
    margin-top: 10px;
}