.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    min-height: 100vh;
}

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 400px;
    height: fit-content;
    padding: 20px;
    border: 1px rgba(255, 255, 255, 0.5) solid;
    border-radius: 5px;
}

.login #loginInputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login #registerSignIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login #passwordRequirements {
    color: #e1f6e0;
    text-align: left;
    margin-top: 0;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.buttons #registerButton {
    color: rgb(124, 180, 226) !important;
    background-color: #69707f !important;
}

.login div {
    width: 90%;
}

.login div label {
    color: rgba(255, 211, 134, 0.87);
}

.login div #resendEmailButton {
    color: rgb(214, 226, 124);
    background-color: #7f7969;
}

.error {
    color: #cf6679;
    font-size: 18px;
    margin-bottom: 10px;
}

  /* Media query for mobile devices with a maximum width of 768px */
@media screen and (max-width: 768px) {
    .main {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    .login {
        margin: 0;
        padding: 15px;
        height: fit-content;
        width: 80%;
    }
}