@font-face {
    font-family: 'Ubuntu Mono';
    src: url(UbuntuMono-Regular.ttf);
}

body {
    margin: 0;
    font-size: 18px;
    font-family: 'Ubuntu Mono', monospace;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;    /* vertical alignment */
    align-items: center;            /* horizontal alignment */
    width: 100%;
    min-width: 360px;
    background-color: #121212b9;
    color: rgba(255, 255, 255, 0.87);
  }

button {
    min-width: 100px;
    max-width: 340px;
    min-height: 35px;
    margin: 5px;
    padding: 10px;
    font-size: 18px;
    color: rgb(176, 215, 158);
    background-color: #697f69;
    border: none;
    border-radius: 4px;
}

button:disabled {
    background-color: #3c433c; 
    color: #505b4b;
}

input[type="text"], input[type="number"], input[type="tel"], input[type="email"], input[type="password"] {
    font-size: 24px;
    padding: 2px;
    width: 75%;
    min-width: 320px;
    max-width: 360px;
    margin-bottom: 5px;
    border-radius: 4px;
    border: 1px solid #3f3e41
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

@media screen and (max-width: 768px) {
    body {
        padding: 0;
        margin-bottom: 30px;
    }
}