.shift {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    gap: 3px;
    border-radius: 5px;
    width: 95%;
}

.item {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    gap: 3px;
    width: 100%;
    font-size: 20px;
    line-height: 22px;
}

.item span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
}

.item span sup {
    font-size: 14px;
    margin-left: 2px;
}

.item span:first-of-type {
    justify-content: flex-start;
    width: 15%;
}

.item span:last-of-type {
    justify-content: flex-end;
    width: 10%;
}

.assigned {
    color: rgba(124, 83, 165, 0.87) !important;
}

.next {
    color: rgba(184, 149, 218, 0.87) !important;
}

.late {
    color: rgb(165, 1, 4, 0.87) !important;
}

.started {
    color: rgb(124, 181, 24, 0.87) !important;
}

.stopped {
    color: rgb(61, 90, 12, 0.87) !important;
}

.disabled span:nth-child(-n+4)  {
    color: rgba(71, 91, 99, 0.87) !important;
    text-decoration: line-through;
}

.A {
    color: rgba(255, 190, 125, 0.67);
}

.B {
    color: rgba(241, 160, 63, 0.67);
}

.C {
    color: rgba(227, 129, 0, 0.67);
}

@media screen and (max-width: 768px) {
    .item {
        font-size: 20px;
        line-height: 30px;
    }
}