.shifts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 3px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    color: rgba(230, 196, 132, 0.87);
}

.header > span span:last-of-type {
    margin-left: 5px;
    color: rgba(255, 255, 255, 0.87);
}

.info {
    display: flex;
    justify-content: space-between;
    width: 98%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 3px;
}

.info span {
    text-align: center;
    color: rgba(245, 205, 63, 0.77);
    width: 25%;
}

.info span:first-of-type {
    width: 15%;
}

.info span:last-of-type {
    width: 10%;
}

.page {
    display: flex;
    justify-content: space-evenly;
}

.page span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
}

.page i {
    cursor: pointer;
}

.page i:hover {
    color: rgba(255, 50, 75, 0.87);
}