.roster {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 3px;
    width: 100%;
}

.patrollers, .hillCaptain {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.patrollers div, .hillCaptain div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
}

.patrollers span, .hillCaptain span {
    padding: 5px;
}

.patrollers img, .hillCaptain img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 5px;
}

.hillCaptain {
    color: rgba(214, 198, 0, 0.87);
}

.patroller {
    color: rgba(255, 175, 175, 0.87);
}

.candidate {
    color: rgba(0, 225, 225, 0.87);
}