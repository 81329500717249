.bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 3px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    color: rgba(255, 157, 71, 0.87);
}

.help {
    cursor: pointer;
    color: rgba(255, 75, 237, 0.87);
}

.logout {
    cursor: pointer;
    color: rgba(255, 125, 75, 0.87);
}

.logout:hover {
    color: rgba(255, 50, 75, 0.87);
}