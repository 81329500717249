.duties {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    line-height: 20px;
    margin-bottom: 20px;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    color: rgba(243, 177, 102, 0.87);
}

.header > span span:last-of-type {
    margin-left: 5px;
    color: rgba(255, 255, 255, 0.87);
}

.duties p span {
    font-weight: bold;
    color: rgba(125, 200, 100, 0.87)
}